.exploreur{
    grid-template-rows: 15px repeat(4,0.25fr);
    width: 100%;
    height: 100%;
    &__top-bar{
        grid-row: 1/1;
        grid-column: 1/5;
    }
    &__side-bar{
        grid-column: 1/1;
        grid-row: 2/6;
        border-right: solid 0.5px;
        
    }
    &__main{
        grid-column: 2/5;
        grid-row: 2/6;
    }
}