.file{
    margin: 0 5px;
    padding: 3px;
    cursor: pointer;
    &:hover{
        background-color: #e5f3ff;
    }
    & img{
        width: 15px;
        margin-right: 5px;
    }
    &__name{
        margin-right: 5px;
        width: calc(100% - 30px);
        text-overflow: ellipsis; 
    }
}