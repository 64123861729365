.gereeCampagne{
    height: 100%;
    &__body{
        height: 100%;
        width: 5px;
        flex-grow: 2;
        flex-shrink: 2;
        padding: 5px;
        ;
    }
    &__side{
        width: max-content;
        min-width: 100px;
        height: 100%;
        padding: 5px;
        border-right: solid 0.1pt;
        &__category{
            width: 100%;
            margin: 5px 0;
        }
        &__name{
            margin-left: 5px;
            cursor: pointer;
            transition: background-color 250ms ease-out 100ms;
            padding: 2px;
            &:hover{
                background: #e5f3ff;
            }
        }
    }
}