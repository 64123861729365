.desktop{
    height: 100%;
    &__main{
        width: 100%;
        height: 95%;
    }
    &__task-bar{
        height: 5%;
        border-top: solid black 0.1pt;
        &__activity{
            width: 100px;
        }
    }
    &__icon{
        height: 50px;
        width: 50px;
        font-size: 10px;
        & img{
            width: 45px;
        }
        & span{
            overflow-wrap: anywhere;
        }
    }
}