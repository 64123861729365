.dir{
    cursor: pointer;
    padding: 3px;
    margin: 5px 0;
    &:hover{
        background-color: #e5f3ff;
    }
    &:focus{
        background-color: #cce8ff;
    }
    & img{
        width: 15px;
        margin-right: 5px;
    }
}