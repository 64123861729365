.crew{
    &__warpper{
        width: 100%;
        max-width: 1000px;
        margin: 10px;
        grid-auto-flow: row dense;
        grid-template-columns: repeat(auto-fit, minmax(200px,280px));
        justify-content: center;
    }
    &__placeholder{
        width: 270px;
    }
    &__card{
        border: solid 0.1pt black;
        border-radius: 10px;
        height: 360px;
        width: 280px;
        flex-shrink: 0;
        margin: 0 5px;
        & h3, h4{
            margin: 10px 0;
        }
        &__name{
            width: 100%;
            text-align: center;
            border-bottom: solid 0.1pt black;
        }
        &__body{
            padding: 10px;
            flex-grow: 2;
            flex-shrink: 2;
        }
        &__crewmate{
            &--edit{
                margin-bottom: 5px;
                height: 90px;
            }
            margin-bottom: 5px;
            & div{
                padding: 0 5px;
                width: 20%;
                min-width: max-content;
                text-align: center;
            }
            &__add{
                &__user{
                    
                        &__liste{
                            height: 65px;
                        }
                    
                }
            }
        }
        &__expedition{
            flex-grow: 2;
        }
    }
}