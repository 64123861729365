.expedition{
    &__warpper{
        height: 100%;
        max-height: 350px;
        width: 620px;
        grid-template-columns: minmax(200px,0.8fr) 0.2fr;
        grid-template-rows: max-content max-content 1fr max-content max-content;
        justify-content: center;
        gap: 20px;
        margin: 10px;
        padding: 5px;
        box-shadow: 0px 0px 7px;
        @media (orientation: portrait) {
            max-height: 100%;
        }
        @media (max-height: 349px) {
            max-height: 100%;
        }
    }
    &__regle{
        align-content: flex-start;
    }
    &__info{
        grid-column: 1/3;
        grid-row: 1/2;
    }
    
    &__header{
        grid-row: 2/3;
        grid-column: 1/2;
    }
    
    &__side{
        grid-row: 2/5;
        grid-column: 2/3;
        @media (orientation: portrait) {
            grid-row: 2/4;   
        }
        &__lor{
            height: 100px;
        }
        &__regle{
            height: 50px;
            position: relative;
            
        }
       
        &__dificulty{
            &__img{
                position: relative;
                width: 50px;
                height: 50px;
                & img{
                    height: 100%;
                }
            }
        }
    }

    &__text{
        &__content{
            flex-grow: 2;
            flex-shrink: 2;
            flex-basis: 100px;
            white-space: break-spaces;
        }
        &__speak{
            width: 30px;
            & img{
                width: 100%;
            }
        }
    }
    &__controle{
        grid-row: 4/5;
        grid-column: 1/2;
        @media (orientation: portrait) {
         grid-column: 1/3;   
        }
        &__mision{
            width: max-content;
            &__number{
                width: 35px;
                border: none;
                border-radius: 0;
                background-color: black;
                color: white;
                clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)
            }
        }
        &__select{
            & > div{
                margin: 0 5px;
            }
        }
        &__btn{
            width: 50px;
            height: 50px;
            @media (max-width:360px)  {
                width: 40px;
                height: 40px;
            }
            &--relative{
                position: relative;
            }
            & img{
                width: 100%;
                height: 100%;
            }
        }
    }
    
    &__attempt{
        grid-column: 1/3;
        grid-row: 5/6;
        margin-top: 5px;

    }

    &__mate{
        margin: 0px 5px;
    }

    &--select{
        width: max-content;
        max-width: 1000px;
        margin: 20px;
        grid-auto-flow: row dense;
        grid-template-columns: repeat(auto-fit, minmax(200px,280px));
        justify-content: center;
        padding: 10px;
    }
    &__card{
        border: solid 0.1pt black;
        border-radius: 15px;
        margin-bottom: 5px;
        flex-shrink: 0;
        width: 235px;
        /* height: 155px; */
        margin: 0 5px;
        &--select{
            height: 250px;
        }
        &__body{
            padding: 10px;
        }
        &__name{
            padding: 3px;
            border-bottom: solid 0.1pt black;
        }
        &__lor{
            margin: 10px 0;
        }
        &__info{
            margin: 5px 0;
        }
    }
}