.attempt{
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin: 0 5px;
    position: relative;
    & img{
        width: 100%;
    }
    &__info{
        position: absolute;
        bottom: 25px;
        width: 90px;
        left: -35px;
        padding: 5px;
        background: white;
        box-shadow: 0px 0px 2px 0px;
    }
    &--help{
        background-color: #01417d;
    }
    &--0{
        background-color:#00d00a ;    
    }
    &--1{
        background-color: #00e539 ;    
    }
    &--2{
        background-color: #78e200;    
    }
    &--3{
        background-color: #ddf600;    
    }
    &--4{
        background-color: #ffe600;    
    }
    &--5{
        background-color: #ffa600;    
    }
    &--6{
        background-color: #ff9100;    
    }
    &--7{
        background-color: #ff6600;    
    }
    &--8{
        background-color: #ff0000;    
    }
    &--9{
        background-color: #8b030e;    
    }
    
}