.regle{
    width: 250px;
    margin:5px;
    &--unsync{
        box-shadow: 0px 0px 5px red;;
    }
    &--sync{
        box-shadow: 0px 0px 5px green;
    }
    & img{
        margin: 5px;
        width: 100%;
    }

    &--campagne{
        & img{
            margin: 5px;
            width: 50px;
        }
    }
    &__instruction{
        flex-grow: 2;
        align-content: end;
        margin-bottom: 5px;
    }
    &--edit{
        & img{
         width: 50px;   
        }
        height: 340px;
    }
    
    &--expedition{
        width: 70px;
        &:hover .regle__info--expedition{
            visibility: visible;
        }
    }
    &__info{
        width: calc(100% - 60px);
        &--expedition{
            visibility: hidden;
                position: absolute;
                top: unset;
                z-index: 2;
                left: -324px;
                background: white;
                height: max-content;
                width: 320px;
                border: solid 0.1pt;
                border-radius: 15px;
                padding: 5px;
                
        }
    }
    
    &__name{
        margin: 5px 0;
        width: calc(100% - 60px);
        & label{
            margin-right: 2px;
            margin-bottom: 3px;
        }

        & input{
            width: 100%;
        }
        
    }
    &__jeu{
        margin: 5px 0;
    }

}