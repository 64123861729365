.mision{
    &--edit{
        position: relative;
        & .mision{
            &__body{
                height: 130px;
            }
            &__name{
                margin-bottom: 5px;
                & label,input{
                    width: 100%;
                    text-align: center;
                }     
            }
            &__lor{
                width: 33%;
                & textarea{
                    width: 100%;
                    height: 120px;
                }
            }
            &__dificulty{
                margin: 0 5px;
                width: 50px;
                height: 100%;
                text-align: center;
                align-items: center;
                & select{
                    width: 50px;
                    height: 50px;
                    text-align: center;
                }
            }
            &__regle{
                flex-grow: 2;
                height: 100%;
            }
            
        }
    }
}