.campagne{
    width: 250px;
    &--edit{
        width: 100%;
        margin: 10px 0;
        height: 100%;
        & .campagne{
            &__name{
                margin-bottom: 5px;
                & label,input{
                    width: 100%;
                    text-align: center;
                }     
            }
            &__lor{
                margin: 5px 0;
                & textarea{
                    width: 100%;
                }
            }
            &__jeu,&__numberOfMision{
                width: 50%;
                margin: 5px 0;
                text-align: center;
            }

            &__misions{
                flex-grow: 2;
            }
            
        }
    }
}