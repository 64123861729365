*{
    box-sizing: border-box;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f100;
  }


  ::-webkit-scrollbar-thumb {
    background: #888;
  }


  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  }
  
  body {
    margin: 0;
    width: 100%;
  }

  .warpper{
    height:100dvh;
    &__header{
      height: 50px;
    }
    &__body{
      height: 100svh;
      margin-top: 10px;
    }
  }
  .content{
    &__main{
      height: 100svh ;
    }
  }

  a{
    text-decoration: none;
    color: black;
    size: 16px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .App{
    width: 100%;
    height: 100vh;
  }

  .titel{
    width: 100%;
    text-align: center;
  }

  .overflow{
    &--hidden{
      overflow: hidden;
    }
  }

  .img{
    &--expand{
      width: 17px;
    }
  }

  table{
    border-collapse: collapse;
    border: solid 0.5pt;
    margin: 5px;
  }

  td, th{
    padding: 5px;
    text-align: center;
    border: solid 0.1pt;
  }

  .relative{
    position: relative;
  }

   