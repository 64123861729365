@keyframes clignoterRed{
    0% {background: #ff878700;}
    1% {background: #ff8787;}
    100% {background: #ff878700;}
   }
  
   @keyframes clignoterGreen{
    0% {background: #ff878700;}
    1% {background: #96ea9f;}
    100% {background: #ff878700;}
   }

.message{
    border-radius: 5px;
    &--ok{
        animation: 0.5s 1 clignoterGreen;
        color: green;
    }
    &--nok{
        animation: 0.5s 1 clignoterRed;
        color: red;
       }
   }