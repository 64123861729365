.parcourir{
    height: 100%;
    &__path{
        width: calc(100% - 50px);
        border: solid black 0.2pt;
        padding: 3px;
        min-height: 26px;
    }
    &__header{
        & img{
            width: 50px;
        }
    }
    &__body{
        flex-grow: 2;
        overflow: scroll;
    }
    &__footer{
        & img{
            width: 50px;
        }
    }
}