.contenair{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;

    &--no-warp{
        flex-wrap: nowrap;
    }

    &--column{
        flex-flow: column;
        &--centre{
            align-items: center;
        }
    }

    &--centre{
        justify-content: center;
        align-items: center;
        align-content: center;
    }
    &--left{
        justify-content: right;
    }
    &--safeCentre{
        margin: auto;
    }
    &--space-evently{
        justify-content: space-evenly;
    }

    &--space-between{
        justify-content: space-between;
    }

    &--90{
        width: 90%;
    }

    &--50{
        width: 45%;
    }

    &--scrollY{
     overflow: auto;
     overflow-x: hidden;   
    }
    &--scroll{
        overflow: auto;  
       }

    &--scrollX{
     overflow-x: scroll;
     overflow-y: hidden;
     padding-bottom: 20px;   
    }


    &--grid{
        display: grid;
    }

    &--tall{
        height: 100%;
    }
}