.grid{
    display: grid;
    grid-gap: 10px;
    &__sub-grid{
        grid-template-columns: subgrid ;
        grid-template-rows:  subgrid;
    }
    &__4c{
        grid-template-columns: repeat(4,0.25fr);
    }
    &__8c{
        grid-template-columns: repeat(8,0.25fr);
    }
    &__4r{
        grid-template-rows:  repeat(4,0.25fr);
    }
    &__8r{
        grid-template-rows:  repeat(8,0.25fr);
        height: 100vh;
    }
}