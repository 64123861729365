.window{
	position: absolute;
	display: grid;
	grid-template-columns: 5px 1fr 5px;
	grid-template-rows: 5px 15px 1fr 5px;
	box-shadow: 0px 0px 11px 1px #dbd8d8;
    background: white;
    &__left{
        grid-column: 1/1;
    }
    &__right{
        grid-column: 3/4;
    }
    &__bottom{
        grid-row: 4/5;
    }   
    &__top{
        grid-row: 1/1;
    }
    &__corner{
        &--right{
            cursor: nwse-resize;
        }
        &--left{
            cursor: nesw-resize
        }
    }

    &__side{
        &--L{
            cursor: n-resize; 
        }
        &--W{
            cursor: w-resize;
            grid-row: 2/4; 
        }
    }

    &__top-bar{
        grid-column: 2/3;
        grid-row: 2/3;
        &__option{
            flex-basis: max-content;
        }
        &__title{
            flex-shrink: 1;
        }
        &__navigation{
            height: 100%;
            & img{
                height: 100%;
            }
        }
    }
    &__content{
        grid-column: 2/3;
        grid-row: 3/4;
    }

}