.carousel{
    width: 890px;
    @media (max-width :920px){
        width: 325px;
    }
    &__content{
        scroll-snap-type: x mandatory;
        &--wide{
            & > *{
                &:nth-child(3n-1){
                    scroll-snap-align: center;
                }
                &:last-child{
                    scroll-snap-align: center;
                }
                
            }
            @media (max-width :920px){
                & > * {
                    scroll-snap-align: center;
                }
            }
        }
        & > * {
            scroll-snap-align: center;
        }
        
    }
}