.context-menu{
    position: fixed;
    z-index: 20;
    background: #fbfbfb;
    min-width: 65px;
    min-height: 75px;
    border-radius: 5px;
    border:solid 0.1pt #ececec ;
    padding: 2px;
    box-shadow: 0px 0px 5px 1px #f5f5f5;
    &__option{
        &:hover{
            background-color:#f2f2f2;
        }
    }
}