.text{
    height: 100%;
    &__content{
        flex-grow: 2;
        flex-shrink: 2;
        flex-basis: 100px;
        white-space: break-spaces;
    }
    &__speak{
        width: 30px;
        & img{
            width: 100%;
        }
    }
}